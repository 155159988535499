import {
  APIComponentAttributes,
  APIEntityType,
  APIFieldAttributes,
  APIFieldType,
  APIReferenceAttributes,
  APIReferenceType,
  APITagAttributes,
  ArdoqId,
} from '@ardoq/api-types';
import { AddedFields, DirtyAttributes } from 'appModelStateEdit/types';
import {
  customPropertyOptionGetterMap as customReferencePropertyOptionGetterMap,
  defaultPropertyOptionGetterMap as defaultReferencePropertyOptionGetterMap,
  isCardinalityAttributeAllowed,
  sourceTargetIdTupleToNameTuple,
  toSourceTargetIdTuple,
} from 'appModelStateEdit/propertiesEditor/referencePropertiesEditor/utils';
import {
  EditorProperty,
  PropertyGroup,
} from 'appModelStateEdit/propertiesEditor/types';
import { splitDateRangeProperties } from 'appModelStateEdit/propertiesEditor/fieldUtils';
import { getEntityTags } from 'appModelStateEdit/propertiesEditor/tagUtils';
import { hasOwnProperty } from 'utils/objectUtils';
import {
  componentAttributesMap,
  defaultAttributesMap,
  FieldType,
  fieldTypeMap,
  getEntityById,
  getEntityModelAndTypeID,
  getFieldLabel,
  getModelTypeDictionaryKey,
  readRawValue,
  referenceAttributesMap,
} from '@ardoq/renderers';
import { ExcludeFalsy } from '@ardoq/common-helpers';
import {
  getMergedCustomMultiEntityProperties,
  getMergedMultiEntityProperties,
} from 'appModelStateEdit/propertiesEditor/multiUtils';
import {
  defaultMultiReferenceAttributeNames,
  defaultReferenceAttributeNames,
  propertiesNotEditableAcrossMultipleWorkspaces as referencePropertiesNotEditableAcrossMultipleWorkspaces,
} from 'appModelStateEdit/propertiesEditor/referencePropertiesEditor/consts';
import {
  defaultComponentAttributeNames,
  defaultMultiComponentAttributeNames,
  propertiesNotEditableAcrossMultipleWorkspaces as componentPropertiesNotEditableAcrossMultipleWorkspaces,
} from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/consts';
import { getDefaultProperties as getDefaultComponentStyleProperties } from 'appModelStateEdit/propertiesEditor/componentStylePropertiesEditor/utils';
import {
  customPropertyOptionGetterMap as customComponentPropertyOptionGetterMap,
  defaultPropertyOptionGetterMap as defaultComponentPropertyOptionGetterMap,
} from 'appModelStateEdit/propertiesEditor/componentPropertiesEditor/utils';
import {
  getCustomProperties as getCustomFieldProperties,
  getDefaultProperties as getDefaultFieldProperties,
} from 'appModelStateEdit/propertiesEditor/fieldPropertiesEditor/utils';
import {
  CALCULATED_FIELD_EDIT_MESSAGE,
  CROSS_WORKSPACE_EDIT_MESSAGE,
  LOCALLY_DERIVED_FIELD_EDIT_MESSAGE,
  LOCKED_MESSAGE,
  MANAGED_FIELD_EDIT_MESSAGE,
  NO_MESSAGE,
} from 'appModelStateEdit/propertiesEditor/consts';
import { SelectOption, SelectOptionsOrGroups } from '@ardoq/select';
import {
  getCustomProperties as getCustomReferenceTypeProperties,
  getDefaultProperties as getDefaultReferenceTypeProperties,
} from 'appModelStateEdit/propertiesEditor/referenceTypePropertiesEditor/utils';
import {
  getCustomProperties as getCustomWorkspaceProperties,
  getDefaultProperties as getDefaultWorkspaceProperties,
} from 'appModelStateEdit/propertiesEditor/workspacePropertiesEditor/utils';
import { snakeCaseToHumanReadable } from 'utils/stringUtils';
import { getValidationErrorMessages } from 'scopeData/editors/validators';
import { fieldInterface } from 'modelInterface/fields/fieldInterface';
import { intersection, isEqual, pick, truncate } from 'lodash';
import {
  richTextEditorConfig,
  richTextEditorConfig2024,
} from 'scopeData/editors/richTextEditorUtils';
import { componentInterface } from 'modelInterface/components/componentInterface';
import { referenceInterface } from '@ardoq/reference-interface';
import type { EnhancedScopeData } from '@ardoq/data-model';
import { workspaceAccessControlInterface } from 'resourcePermissions/accessControlHelpers/workspace';
import { PermissionContext } from '@ardoq/access-control';
import { componentAccessControlOperation } from 'resourcePermissions/accessControlHelpers/component';
import { graphSearchAccessControlHelpers } from 'resourcePermissions/accessControlHelpers/graphSearch';
import { getActiveScenarioState } from 'streams/activeScenario/activeScenario$';
import { scenarioAccessControlInterface } from 'resourcePermissions/accessControlHelpers/scenario';
import { referenceAccessControlOperation } from 'resourcePermissions/accessControlHelpers/reference';
import { SubdivisionsContext } from '@ardoq/subdivisions';
import { subdivisionAccessControlInterface } from 'resourcePermissions/accessControlHelpers/subdivisions';
import {
  hexColorToRgbaObj,
  isValidHexColor,
  rgbaObjToStr,
  rgbaStringToObj,
} from '@ardoq/color-helpers';
import { logWarn } from '@ardoq/logging';
import { Features, hasFeature } from '@ardoq/features';
import { fieldOps } from '../../models/utils/fieldOps';
import { SubdivisionCreationContextState } from 'subdivisions/subdivisionCreationContext/types';
import { subdivisionCreationContextInterface } from 'subdivisions/subdivisionCreationContext/subdivisionCreationContextInterface';
import { isDateRangeFieldType } from '@ardoq/date-range';
import { ScenarioModeState } from 'scope/types';
import { activeScenarioOperations } from 'streams/activeScenario/activeScenarioOperations';
import { IntendedRefTypes } from './intendedRefTypes$';

export type TypeOptionsExtraContext = {
  refTypesSortedByMostUsed?: APIReferenceType[] | null;
  intendedRefTypes?: IntendedRefTypes;
};

export type DefaultPropertyOptionGetterMap = Map<
  FieldType,
  (
    entityID: ArdoqId,
    enhancedScopeData: EnhancedScopeData,
    permissionContext: PermissionContext,
    subdivisionsContext: SubdivisionsContext,
    /**
     * Allows for additional context to be passed to the option getter.
     */
    extraContext?: TypeOptionsExtraContext
  ) => SelectOptionsOrGroups<any>
>;

export type CustomPropertyOptionGetterMap = Map<
  FieldType,
  (field: APIFieldAttributes) => SelectOption<string>[] | undefined
>;

const isManaged = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityID: ArdoqId
) => {
  if (entityType === APIEntityType.REFERENCE) {
    return referenceInterface.isExternallyManaged(entityID);
  }
  if (entityType === APIEntityType.COMPONENT) {
    return componentInterface.isExternallyManaged(entityID);
  }
  return false;
};

const isEditingAcrossMultipleWorkspaces = (
  enhancedScopeData: EnhancedScopeData
) => enhancedScopeData?.workspaces?.length > 1;

const isMultiEditing = (entityIDs: ArdoqId[]) => entityIDs.length > 1;

export const getPrimaryButtonLabel = (
  isCreatingNewEntity: boolean,
  isCalculatedField: boolean,
  isCalculatedFieldWithoutQuery: boolean
) => {
  if (!isCreatingNewEntity && isCalculatedFieldWithoutQuery) {
    return 'Save & edit query';
  }
  if (!isCreatingNewEntity) {
    return 'Save';
  }
  if (isCalculatedField) {
    return 'Create & edit query';
  }
  return 'Create';
};

export const isAnyEntityLocked = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData
) => {
  if (
    entityType !== APIEntityType.COMPONENT &&
    entityType !== APIEntityType.REFERENCE
  ) {
    return false;
  }
  return entityIDs.some(
    entityID => getEntityById(entityType, entityID, enhancedScopeData)?.lock
  );
};

export const getLockedMessage = (isAnyLocked: boolean) =>
  isAnyLocked ? LOCKED_MESSAGE : '';

export const getMultiEditingMessage = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  isEditingComponentStyle?: boolean
) => {
  if (!isMultiEditing(entityIDs) && isEditingComponentStyle) {
    const name = enhancedScopeData.components.find(component =>
      entityIDs.includes(component._id)
    )?.name;
    return `Editing 1 component (${name})`;
  }
  if (!isMultiEditing(entityIDs)) {
    return;
  }
  const charLimit = 300;
  if (entityType === APIEntityType.COMPONENT) {
    const componentNames = enhancedScopeData.components
      .filter(component => entityIDs.includes(component._id))
      .map(component => component.name);
    const message = `Editing ${
      entityIDs.length
    } components (${componentNames.join(', ')})`;
    return truncate(message, { length: charLimit });
  }
  if (entityType === APIEntityType.REFERENCE) {
    const referenceNames = enhancedScopeData.references
      .filter(reference => entityIDs.includes(reference._id))
      .map(toSourceTargetIdTuple)
      .map(sourceTargetIdTupleToNameTuple)
      .map(componentNames => componentNames.join(' -> '));
    const message = `Editing ${
      entityIDs.length
    } references (${referenceNames.join(', ')})`;
    return truncate(message, { length: charLimit });
  }
};

export const getSidebarInstanceId = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  model?: ArdoqId
) => {
  if (entityType === APIEntityType.WORKSPACE) {
    const entityID = entityIDs[0];
    const workspace = enhancedScopeData.workspacesById[entityID];
    const version = workspace._version;
    return `${entityType}-${entityID}-${version}`;
  }
  return entityIDs
    .map(entityID => {
      const entity = getEntityById(
        entityType,
        entityID,
        enhancedScopeData,
        model
      );
      if (!entity) return;
      // @ts-expect-error _version isn't a property on APIComponentType, which entity might be.
      const entityVersion = entity._version;
      if (!entityVersion && model) {
        const version = getEntityById(
          APIEntityType.MODEL,
          model,
          enhancedScopeData
        )?._version;
        return `${entityType}-${entityID}-${version}`;
      }
      return `${entityType}-${entityID}-${entityVersion}`;
    })
    .join('-');
};

export const getHeaderTitle = (
  entityType: APIEntityType,
  isCreatingNewEntity: boolean,
  isEditingComponentStyle?: boolean
) => {
  if (isEditingComponentStyle) {
    return `Edit component style properties`;
  }
  return isCreatingNewEntity
    ? `Create ${snakeCaseToHumanReadable(entityType)}`
    : `Edit ${snakeCaseToHumanReadable(entityType)} properties`;
};

export const canShowTags = (
  entityType: APIEntityType,
  isEditingComponentStyle?: boolean
) =>
  !isEditingComponentStyle &&
  (entityType === APIEntityType.COMPONENT ||
    entityType === APIEntityType.REFERENCE);

export const canDelete = (
  entityType: APIEntityType,
  isCreatingNewEntity: boolean,
  isLocked: boolean,
  isEditingComponentStyle?: boolean
) =>
  !isCreatingNewEntity &&
  !isLocked &&
  !isEditingComponentStyle &&
  (entityType === APIEntityType.COMPONENT ||
    entityType === APIEntityType.REFERENCE);

export const canAddField = (
  permissionContext: PermissionContext,
  activeScenarioState: ScenarioModeState,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  isLocked: boolean,
  isEditingComponentStyle?: boolean
) => {
  if (
    hasFeature(Features.QUICK_START) ||
    hasFeature(Features.METAMODEL_EDITOR_BETA)
  ) {
    return false;
  }
  return (
    (entityType === APIEntityType.COMPONENT ||
      entityType === APIEntityType.REFERENCE) &&
    !isEditingAcrossMultipleWorkspaces(enhancedScopeData) &&
    !isLocked &&
    !isEditingComponentStyle &&
    enhancedScopeData.workspaces.every(({ _id }) =>
      workspaceAccessControlInterface.canAddFieldsToWorkspace(
        permissionContext,
        _id,
        activeScenarioState
      )
    )
  );
};

// This applies when single-editing a Component or Reference and not being a Workspace Admin
export const getAddFieldAdminMessage = (
  permissionContext: PermissionContext,
  activeScenarioState: ScenarioModeState,
  entityType: APIEntityType,
  enhancedScopeData: EnhancedScopeData,
  isLocked: boolean,
  isEditingComponentStyle?: boolean
) => {
  if (
    canAddField(
      permissionContext,
      activeScenarioState,
      entityType,
      enhancedScopeData,
      isLocked,
      isEditingComponentStyle
    )
  ) {
    return null;
  }
  if (
    enhancedScopeData.workspaces.every(({ _id }) =>
      workspaceAccessControlInterface.canAddFieldsToWorkspace(
        permissionContext,
        _id,
        // We ignore it here because somewhere else we check, if it is scenario mode we show different message.
        null
      )
    )
  ) {
    return null;
  }
  return `You don't have permissions to add a field to this workspace. Contact the admin for permission.`;
};

export const isEntityCalculatedField = (
  entityType: APIEntityType,
  entityID: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  if (entityType !== APIEntityType.FIELD) {
    return false;
  }
  const field = enhancedScopeData.fieldsById[entityID];

  return field ? fieldOps.isCalculatedField(field) : false;
};

export const isEntityCalculatedFieldWithoutQuery = (
  entityType: APIEntityType,
  entityID: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  if (entityType !== APIEntityType.FIELD) {
    return false;
  }
  const field = enhancedScopeData.fieldsById[entityID];

  return field ? fieldOps.isCalculatedFieldWithoutQuery(field) : false;
};

export const isEntityDateRangeField = (
  entityType: APIEntityType,
  entityID: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  if (entityType !== APIEntityType.FIELD) {
    return false;
  }
  const field = enhancedScopeData.fieldsById[entityID];

  return field ? isDateRangeFieldType(field.type) : false;
};

const isPropertyDisabled = (
  propertyName: string,
  isExternallyManaged: boolean,
  propertiesNotEditableAcrossMultipleWorkspaces: string[],
  enhancedScopeData: EnhancedScopeData,
  canEditEntity: boolean
) => {
  return (
    !canEditEntity ||
    isExternallyManaged ||
    (isEditingAcrossMultipleWorkspaces(enhancedScopeData) &&
      propertiesNotEditableAcrossMultipleWorkspaces.includes(propertyName))
  );
};

const getDisabledMessage = ({
  isDisabled,
  isExternallyManaged,
  isCalculated,
  isLocallyDerived,
  isReadOnlyInTheZone,
}: {
  isDisabled: boolean;
  isExternallyManaged: boolean;
  isCalculated?: boolean;
  isLocallyDerived?: boolean;
  isReadOnlyInTheZone?: boolean;
}) => {
  if (isCalculated) {
    return CALCULATED_FIELD_EDIT_MESSAGE;
  }
  if (isLocallyDerived) {
    return LOCALLY_DERIVED_FIELD_EDIT_MESSAGE;
  }
  if (isExternallyManaged) {
    return MANAGED_FIELD_EDIT_MESSAGE;
  }
  if (isReadOnlyInTheZone) {
    return NO_MESSAGE;
  }
  if (isDisabled) {
    return CROSS_WORKSPACE_EDIT_MESSAGE;
  }
};

const getValue = (
  attributes: APIComponentAttributes | APIReferenceAttributes,
  field: APIFieldAttributes,
  isCreatingNewEntity: boolean,
  isNewlyAddedField: boolean
) => {
  if (
    !(isCreatingNewEntity || isNewlyAddedField) ||
    field.type === APIFieldType.LIST ||
    field.type === APIFieldType.SELECT_MULTIPLE_LIST
  ) {
    return attributes[field.name];
  }
  return hasOwnProperty(attributes, field.name)
    ? attributes[field.name]
    : field.defaultValue;
};

const getEntityModelTypeKey = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityID: ArdoqId,
  enhancedScopeData: EnhancedScopeData
) => {
  const { model, typeId } = getEntityModelAndTypeID(
    entityType,
    entityID,
    enhancedScopeData
  );
  if (!model || !typeId) {
    return null;
  }
  return getModelTypeDictionaryKey(model, typeId);
};

const attributeNameToDefaultPropertyObject =
  (
    entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
    entityID: ArdoqId,
    enhancedScopeData: EnhancedScopeData,
    attributeTypeMap: Map<string, FieldType>,
    optionGetterMap: DefaultPropertyOptionGetterMap,
    propertiesNotEditableAcrossMultipleWorkspaces: string[],
    dirtyAttributes: DirtyAttributes,
    permissionContext: PermissionContext,
    subdivisionsContext: SubdivisionsContext,
    isCreatingNewEntity: boolean,
    refTypesSortedByMostUsed?: APIReferenceType[] | null,
    intendedRefTypes?: IntendedRefTypes
  ): ((attributeName: string) => EditorProperty | null) =>
  (attributeName: string) => {
    const type =
      defaultAttributesMap.get(attributeName) ??
      attributeTypeMap.get(attributeName);
    if (!type) {
      return null;
    }
    const label = getFieldLabel({
      fieldName: attributeName,
      enhancedScopeData,
      entityType,
    });
    const rootWorkspaceId = readRawValue(
      entityType,
      entityID,
      'rootWorkspace',
      enhancedScopeData
    );
    const isEntityExternallyManaged = isManaged(entityType, entityID);
    const isFieldExternallyManaged =
      fieldInterface.isExternallyManagedForEntityTypeWithinWorkspace(
        entityType,
        attributeName,
        rootWorkspaceId
      );
    const activeScenarioState = getActiveScenarioState();

    let canEditEntity =
      workspaceAccessControlInterface.canEditWorkspace(
        permissionContext,
        rootWorkspaceId,
        activeScenarioState
      ) ||
      subdivisionAccessControlInterface.hasEditableZone(
        permissionContext,
        subdivisionsContext
      );
    if (activeScenarioOperations.isInScenarioMode(activeScenarioState)) {
      canEditEntity = scenarioAccessControlInterface.canEditActiveScenario(
        permissionContext,
        activeScenarioState
      );
    } else if (entityType === APIEntityType.COMPONENT && !canEditEntity) {
      let componentData: APIComponentAttributes | null =
        enhancedScopeData.componentsById[entityID];
      if (isCreatingNewEntity) {
        componentData = componentData?.parent
          ? enhancedScopeData.componentsById[componentData.parent]
          : null;
      }
      canEditEntity =
        !!componentData &&
        componentAccessControlOperation.canEditComponent({
          permissionContext,
          component: componentData,
          subdivisionsContext,
        });
    } else if (entityType === APIEntityType.REFERENCE) {
      const referenceData = enhancedScopeData.referencesById[entityID];
      canEditEntity =
        referenceData &&
        referenceAccessControlOperation.canEditReference({
          permissionContext,
          reference: referenceData,
          subdivisionsContext,
        });
    }

    const isExternallyManaged =
      isEntityExternallyManaged && isFieldExternallyManaged;
    const externallyManagedInfo = {
      isExternallyManaged,
      externallyManagedWorkspaceId: rootWorkspaceId,
    };
    const isDisabled = isPropertyDisabled(
      attributeName,
      isExternallyManaged,
      propertiesNotEditableAcrossMultipleWorkspaces,
      enhancedScopeData,
      canEditEntity
    );
    const value = readRawValue(
      entityType,
      entityID,
      attributeName,
      enhancedScopeData
    );
    const isDirty = dirtyAttributes.has(attributeName);
    const errorMessages = getValidationErrorMessages(type, value);

    return {
      type,
      name: attributeName,
      label,
      value,
      isDirty,
      errorMessages,
      options: optionGetterMap.get(type)?.(
        entityID,
        enhancedScopeData,
        permissionContext,
        subdivisionsContext,
        { refTypesSortedByMostUsed, intendedRefTypes }
      ),
      isDisabled,
      disabledMessage: getDisabledMessage({ isDisabled, isExternallyManaged }),
      ...(isExternallyManaged ? externallyManagedInfo : {}),
      ...(type === FieldType.DESCRIPTION
        ? {
            config: richTextEditorConfig,
            toolbarExtensions: richTextEditorConfig2024,
            ...(entityType === APIEntityType.COMPONENT
              ? {
                  // These are used by the component description generator
                  componentName:
                    enhancedScopeData.componentsById[entityID].name,
                  componentTypeName:
                    enhancedScopeData.componentsById[entityID].type,
                  workspaceName:
                    enhancedScopeData.workspacesById[rootWorkspaceId].name,
                  entityType,
                }
              : {}),
          }
        : {}),
    };
  };

const fieldAttributesToCustomPropertyObject =
  (
    entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
    attributes: APIComponentAttributes | APIReferenceAttributes,
    enhancedScopeData: EnhancedScopeData,
    isEntityExternallyManaged: boolean,
    addedFields: AddedFields,
    dirtyAttributes: DirtyAttributes,
    isCreatingNewEntity: boolean,
    optionGetterMap: CustomPropertyOptionGetterMap,
    subdivisionsContext: SubdivisionsContext,
    permissionContext: PermissionContext
  ): ((field: APIFieldAttributes) => EditorProperty | undefined) =>
  (field: APIFieldAttributes) => {
    const isCalculated = fieldOps.isCalculatedField(field);
    const isLocallyDerived = fieldOps.isLocallyDerivedField(field);
    const isNewlyAddedField = addedFields.has(field.name);
    if (isCalculated && !isNewlyAddedField) {
      return;
    }
    const type = fieldTypeMap.get(field.type)!;
    const label = getFieldLabel({
      fieldName: field.name,
      enhancedScopeData,
      entityType,
    });
    const rootWorkspaceId = attributes.rootWorkspace;
    const isFieldExternallyManaged =
      fieldInterface.isExternallyManagedForEntityTypeWithinWorkspace(
        entityType,
        field.name,
        rootWorkspaceId
      );
    const isExternallyManaged =
      isEntityExternallyManaged && isFieldExternallyManaged;
    const externallyManagedInfo = {
      isExternallyManaged,
      externallyManagedWorkspaceId: rootWorkspaceId,
    };

    const activeScenarioState = getActiveScenarioState();
    let hasNoEditAccess = !workspaceAccessControlInterface.canEditWorkspace(
      permissionContext,
      rootWorkspaceId,
      activeScenarioState
    );
    if (activeScenarioOperations.isInScenarioMode(activeScenarioState)) {
      hasNoEditAccess = !scenarioAccessControlInterface.canEditActiveScenario(
        permissionContext,
        activeScenarioState
      );
    } else if (hasNoEditAccess) {
      if (entityType === APIEntityType.COMPONENT) {
        let componentData: null | APIComponentAttributes = null;
        if (!isCreatingNewEntity) {
          if (enhancedScopeData.componentsById[attributes._id]) {
            componentData = attributes as APIComponentAttributes;
          }
        } else {
          componentData = attributes.parent
            ? enhancedScopeData.componentsById[attributes.parent]
            : null;
        }
        if (componentData) {
          hasNoEditAccess = !componentAccessControlOperation.canEditField({
            component: componentData,
            fieldId: field._id,
            permissionContext,
            subdivisionsContext,
          });
        }
      } else if (entityType === APIEntityType.REFERENCE) {
        hasNoEditAccess = !referenceAccessControlOperation.canEditReference({
          reference: attributes as APIReferenceAttributes,
          permissionContext,
          subdivisionsContext,
        });
      }
    }
    const isDisabled =
      isExternallyManaged ||
      isCalculated ||
      isLocallyDerived ||
      hasNoEditAccess;
    const value = getValue(
      attributes,
      field,
      isCreatingNewEntity,
      isNewlyAddedField
    );
    const isDirty = dirtyAttributes.has(field.name);
    const errorMessages = getValidationErrorMessages(type, value);
    return {
      type,
      name: field.name,
      label,
      description: field.description,
      value,
      isDirty,
      errorMessages,
      options: optionGetterMap.get(type)?.(field),
      isDisabled,
      disabledMessage: getDisabledMessage({
        isDisabled,
        isExternallyManaged,
        isCalculated,
        isLocallyDerived,
        isReadOnlyInTheZone: hasNoEditAccess,
      }),
      ...(isExternallyManaged ? externallyManagedInfo : {}),
      isRemovable: isNewlyAddedField,
    };
  };

const getDefaultEntityProperties = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityID: ArdoqId,
  enhancedScopeData: EnhancedScopeData,
  attributeNames: string[],
  attributeTypeMap: Map<string, FieldType>,
  defaultPropertyOptionGetterMap: DefaultPropertyOptionGetterMap,
  propertiesNotEditableAcrossMultipleWorkspaces: string[],
  dirtyAttributes: DirtyAttributes,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  isCreatingNewEntity: boolean,
  refTypesSortedByMostUsed?: APIReferenceType[] | null,
  intendedRefTypes?: IntendedRefTypes
): EditorProperty[] => {
  return attributeNames
    .map(
      attributeNameToDefaultPropertyObject(
        entityType,
        entityID,
        enhancedScopeData,
        attributeTypeMap,
        defaultPropertyOptionGetterMap,
        propertiesNotEditableAcrossMultipleWorkspaces,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext,
        isCreatingNewEntity,
        refTypesSortedByMostUsed,
        intendedRefTypes
      )
    )
    .filter(ExcludeFalsy);
};

const getDefaultMultiEntityProperties = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  attributeNames: string[],
  attributeTypeMap: Map<string, FieldType>,
  defaultPropertyOptionGetterMap: DefaultPropertyOptionGetterMap,
  propertiesNotEditableAcrossMultipleWorkspaces: string[],
  dirtyAttributes: DirtyAttributes,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  refTypesSortedByMostUsed?: APIReferenceType[] | null,
  intendedRefTypes?: IntendedRefTypes
): EditorProperty[] => {
  const propertiesPerEntity = entityIDs.map(entityID =>
    getDefaultEntityProperties(
      entityType,
      entityID,
      enhancedScopeData,
      attributeNames,
      attributeTypeMap,
      defaultPropertyOptionGetterMap,
      propertiesNotEditableAcrossMultipleWorkspaces,
      dirtyAttributes,
      permissionContext,
      subdivisionsContext,
      false,
      refTypesSortedByMostUsed,
      intendedRefTypes
    )
  );
  return getMergedMultiEntityProperties(propertiesPerEntity);
};

const getCustomEntityProperties = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityID: ArdoqId,
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  addedFields: AddedFields,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  customPropertyOptionGetterMap: CustomPropertyOptionGetterMap,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext
): EditorProperty[] => {
  const attributes = getEntityById(entityType, entityID, enhancedScopeData);
  if (!attributes) {
    return [];
  }
  const key = getEntityModelTypeKey(entityType, entityID, enhancedScopeData);
  if (!key) {
    return [];
  }
  const entityFieldDict = enhancedScopeData.fieldsByModelIdAndTypeId[key];
  if (!entityFieldDict) {
    return [];
  }
  const entityFields = Object.values(entityFieldDict);

  return entityFields
    .filter(field => {
      if (entityType !== APIEntityType.COMPONENT) {
        return true;
      }

      if (activeScenarioOperations.isInScenarioMode(activeScenarioState)) {
        return scenarioAccessControlInterface.canEditActiveScenario(
          permissionContext,
          activeScenarioState
        );
      }

      return !componentAccessControlOperation.hasNoAccessToField({
        component: attributes as APIComponentAttributes,
        fieldId: field._id,
        permissionContext,
        subdivisionsContext,
      });
    })
    .map(
      fieldAttributesToCustomPropertyObject(
        entityType,
        attributes,
        enhancedScopeData,
        isManaged(entityType, entityID),
        addedFields,
        dirtyAttributes,
        isCreatingNewEntity,
        customPropertyOptionGetterMap,
        subdivisionsContext,
        permissionContext
      )
    )
    .filter(ExcludeFalsy);
};

export const getCustomMultiEntityProperties = (
  entityType: APIEntityType.COMPONENT | APIEntityType.REFERENCE,
  entityIDs: ArdoqId[],
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  addedFields: AddedFields,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  customPropertyOptionGetterMap: CustomPropertyOptionGetterMap,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext
): EditorProperty[] => {
  const propertiesPerEntity = entityIDs.map(entityID => {
    return getCustomEntityProperties(
      entityType,
      entityID,
      activeScenarioState,
      enhancedScopeData,
      addedFields,
      dirtyAttributes,
      isCreatingNewEntity,
      customPropertyOptionGetterMap,
      subdivisionsContext,
      permissionContext
    );
  });
  const uniqueModelTypeKeys = new Set(
    entityIDs
      .map(entityID =>
        getEntityModelTypeKey(entityType, entityID, enhancedScopeData)
      )
      .filter(ExcludeFalsy)
  );
  const fieldNamesPerType = Array.from(uniqueModelTypeKeys).map(
    modelTypeKey => {
      // It can be that no fields are defined yet for a given type.
      const fields =
        enhancedScopeData.fieldsByModelIdAndTypeId[modelTypeKey] ?? {};
      return Object.keys(fields);
    }
  );
  const commonPropertyNames = new Set(intersection(...fieldNamesPerType));
  return getMergedCustomMultiEntityProperties(
    propertiesPerEntity,
    commonPropertyNames
  );
};

const getDefaultProperties = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  isCreatingNewEntity: boolean,
  refTypesSortedByMostUsed?: APIReferenceType[] | null,
  intendedRefTypes?: IntendedRefTypes
): EditorProperty[] => {
  switch (entityType) {
    case APIEntityType.COMPONENT: {
      if (isMultiEditing(entityIDs)) {
        return getDefaultMultiEntityProperties(
          entityType,
          entityIDs,
          enhancedScopeData,
          defaultMultiComponentAttributeNames,
          componentAttributesMap,
          defaultComponentPropertyOptionGetterMap,
          componentPropertiesNotEditableAcrossMultipleWorkspaces,
          dirtyAttributes,
          permissionContext,
          subdivisionsContext
        );
      }
      return getDefaultEntityProperties(
        entityType,
        entityIDs[0],
        enhancedScopeData,
        defaultComponentAttributeNames,
        componentAttributesMap,
        defaultComponentPropertyOptionGetterMap,
        componentPropertiesNotEditableAcrossMultipleWorkspaces,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext,
        isCreatingNewEntity
      );
    }
    case APIEntityType.REFERENCE: {
      if (isMultiEditing(entityIDs)) {
        return getDefaultMultiEntityProperties(
          entityType,
          entityIDs,
          enhancedScopeData,
          defaultMultiReferenceAttributeNames.filter(name =>
            entityIDs.every(entityID =>
              isCardinalityAttributeAllowed(entityID, enhancedScopeData)(name)
            )
          ),
          referenceAttributesMap,
          defaultReferencePropertyOptionGetterMap,
          referencePropertiesNotEditableAcrossMultipleWorkspaces,
          dirtyAttributes,
          permissionContext,
          subdivisionsContext,
          refTypesSortedByMostUsed,
          intendedRefTypes
        );
      }
      return getDefaultEntityProperties(
        entityType,
        entityIDs[0],
        enhancedScopeData,
        defaultReferenceAttributeNames.filter(
          isCardinalityAttributeAllowed(entityIDs[0], enhancedScopeData)
        ),
        referenceAttributesMap,
        defaultReferencePropertyOptionGetterMap,
        referencePropertiesNotEditableAcrossMultipleWorkspaces,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext,
        isCreatingNewEntity,
        refTypesSortedByMostUsed,
        intendedRefTypes
      );
    }
    default:
      // TODO implement other entity types
      return [];
  }
};

const getComponentPropertyGroups = (
  entityIDs: ArdoqId[],
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  addedFields: AddedFields,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext,
  subdivisionCreationContext: SubdivisionCreationContextState
): PropertyGroup[] => {
  if (isMultiEditing(entityIDs)) {
    return [
      {
        properties: getDefaultProperties(
          APIEntityType.COMPONENT,
          entityIDs,
          enhancedScopeData,
          dirtyAttributes,
          permissionContext,
          subdivisionsContext,
          false
        ),
      },
      {
        title: 'Fields',
        properties: getCustomMultiEntityProperties(
          APIEntityType.COMPONENT,
          entityIDs,
          activeScenarioState,
          enhancedScopeData,
          addedFields,
          dirtyAttributes,
          isCreatingNewEntity,
          customComponentPropertyOptionGetterMap,
          subdivisionsContext,
          permissionContext
        ),
      },
    ];
  }
  return [
    {
      properties: getDefaultProperties(
        APIEntityType.COMPONENT,
        entityIDs,
        enhancedScopeData,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext,
        isCreatingNewEntity
      ),
    },
    ...(hasFeature(Features.PERMISSION_ZONES) && isCreatingNewEntity
      ? [
          {
            title: 'Permissions',
            properties: [
              subdivisionCreationContextInterface.getSubdivisionCreationContextProperty(
                {
                  subdivisionCreationContext,
                  subdivisionsContext,
                  parentComponentId: readRawValue(
                    APIEntityType.COMPONENT,
                    entityIDs[0],
                    'parent',
                    enhancedScopeData
                  ),
                }
              ),
            ],
          },
        ]
      : []),
    {
      title: 'Fields',
      properties: getCustomEntityProperties(
        APIEntityType.COMPONENT,
        entityIDs[0],
        activeScenarioState,
        enhancedScopeData,
        addedFields,
        dirtyAttributes,
        isCreatingNewEntity,
        customComponentPropertyOptionGetterMap,
        subdivisionsContext,
        permissionContext
      ),
    },
  ];
};
const getComponentStylePropertyGroups = (
  entityIDs: ArdoqId[],
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext
): PropertyGroup[] => {
  return [
    {
      title: 'Style',
      properties: getDefaultComponentStyleProperties(
        entityIDs[0],
        activeScenarioState,
        enhancedScopeData,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext
      ),
    },
  ];
};
const getReferencePropertyGroups = (
  entityIDs: ArdoqId[],
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  addedFields: AddedFields,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext,
  refTypesSortedByMostUsed?: APIReferenceType[] | null,
  intendedRefTypes?: IntendedRefTypes
): PropertyGroup[] => {
  if (isMultiEditing(entityIDs)) {
    return [
      {
        properties: getDefaultProperties(
          APIEntityType.REFERENCE,
          entityIDs,
          enhancedScopeData,
          dirtyAttributes,
          permissionContext,
          subdivisionsContext,
          isCreatingNewEntity,
          refTypesSortedByMostUsed,
          intendedRefTypes
        ),
      },
      {
        title: 'Fields',
        properties: getCustomMultiEntityProperties(
          APIEntityType.REFERENCE,
          entityIDs,
          activeScenarioState,
          enhancedScopeData,
          addedFields,
          dirtyAttributes,
          isCreatingNewEntity,
          customReferencePropertyOptionGetterMap,
          subdivisionsContext,
          permissionContext
        ),
      },
    ];
  }
  return [
    {
      properties: getDefaultProperties(
        APIEntityType.REFERENCE,
        entityIDs,
        enhancedScopeData,
        dirtyAttributes,
        permissionContext,
        subdivisionsContext,
        isCreatingNewEntity,
        refTypesSortedByMostUsed,
        intendedRefTypes
      ),
    },
    {
      title: 'Fields',
      properties: getCustomEntityProperties(
        APIEntityType.REFERENCE,
        entityIDs[0],
        activeScenarioState,
        enhancedScopeData,
        addedFields,
        dirtyAttributes,
        isCreatingNewEntity,
        customReferencePropertyOptionGetterMap,
        subdivisionsContext,
        permissionContext
      ),
    },
  ];
};
const getFieldPropertyGroups = (
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  canCreateGraphFilter: boolean
): PropertyGroup[] => {
  return [
    {
      properties: getDefaultFieldProperties(
        entityIDs[0],
        enhancedScopeData,
        dirtyAttributes,
        isCreatingNewEntity,
        canCreateGraphFilter
      ),
    },
    {
      title: 'Applies to',
      properties: getCustomFieldProperties(
        entityIDs[0],
        enhancedScopeData,
        dirtyAttributes
      ),
    },
  ];
};
const getReferenceTypePropertyGroups = (
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  model: ArdoqId,
  dirtyAttributes: DirtyAttributes
): PropertyGroup[] => {
  return [
    {
      properties: getDefaultReferenceTypeProperties(
        entityIDs[0],
        enhancedScopeData,
        model,
        dirtyAttributes
      ),
    },
    {
      title: 'Style',
      properties: getCustomReferenceTypeProperties(
        entityIDs[0],
        enhancedScopeData,
        model,
        dirtyAttributes
      ),
    },
  ];
};
const getWorkspacePropertyGroups = (
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  dirtyAttributes: DirtyAttributes
): PropertyGroup[] => {
  return [
    {
      properties: [
        ...getDefaultWorkspaceProperties(
          entityIDs[0],
          enhancedScopeData,
          dirtyAttributes
        ),
        ...getCustomWorkspaceProperties(
          entityIDs[0],
          enhancedScopeData,
          dirtyAttributes
        ),
      ],
    },
  ];
};

export const getPropertyGroups = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  activeScenarioState: ScenarioModeState,
  enhancedScopeData: EnhancedScopeData,
  addedFields: AddedFields,
  dirtyAttributes: DirtyAttributes,
  isCreatingNewEntity: boolean,
  subdivisionsContext: SubdivisionsContext,
  permissionContext: PermissionContext,
  subdivisionCreationContext: SubdivisionCreationContextState,
  model?: ArdoqId,
  isEditingComponentStyle?: boolean,
  refTypesSortedByMostUsed?: APIReferenceType[] | null,
  intendedRefTypes?: IntendedRefTypes
): PropertyGroup[] => {
  switch (entityType) {
    case APIEntityType.COMPONENT: {
      if (isEditingComponentStyle) {
        return getComponentStylePropertyGroups(
          entityIDs,
          activeScenarioState,
          enhancedScopeData,
          dirtyAttributes,
          permissionContext,
          subdivisionsContext
        );
      }
      return getComponentPropertyGroups(
        entityIDs,
        activeScenarioState,
        enhancedScopeData,
        addedFields,
        dirtyAttributes,
        isCreatingNewEntity,
        subdivisionsContext,
        permissionContext,
        subdivisionCreationContext
      );
    }
    case APIEntityType.REFERENCE: {
      return getReferencePropertyGroups(
        entityIDs,
        activeScenarioState,
        enhancedScopeData,
        addedFields,
        dirtyAttributes,
        isCreatingNewEntity,
        subdivisionsContext,
        permissionContext,
        refTypesSortedByMostUsed,
        intendedRefTypes
      );
    }
    case APIEntityType.FIELD: {
      return getFieldPropertyGroups(
        entityIDs,
        enhancedScopeData,
        dirtyAttributes,
        isCreatingNewEntity,
        graphSearchAccessControlHelpers.canCreateGraphFilter(permissionContext)
      );
    }
    case APIEntityType.REFERENCE_TYPE: {
      return getReferenceTypePropertyGroups(
        entityIDs,
        enhancedScopeData,
        model!,
        dirtyAttributes
      );
    }
    case APIEntityType.WORKSPACE: {
      return getWorkspacePropertyGroups(
        entityIDs,
        enhancedScopeData,
        dirtyAttributes
      );
    }
    default:
      // TODO implement other entity types
      return [];
  }
};

export const getTags = (
  entityType: APIEntityType,
  entityIDs: ArdoqId[],
  enhancedScopeData: EnhancedScopeData,
  permissionContext: PermissionContext,
  subdivisionsContext: SubdivisionsContext,
  activeScenarioState: ScenarioModeState,
  isCreatingNewEntity: boolean
) => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
    case APIEntityType.REFERENCE: {
      return getEntityTags(
        entityType,
        entityIDs,
        enhancedScopeData,
        permissionContext,
        subdivisionsContext,
        activeScenarioState,
        isCreatingNewEntity
      );
    }
    default:
      // TODO implement other entity types
      return { value: [], options: [] };
  }
};

/*
 *  -> Attributes
 */

export const attributesFromProperties = (propertyGroups: PropertyGroup[]) => {
  return Object.fromEntries(
    [
      ...propertyGroups.flatMap(propertyGroup =>
        splitDateRangeProperties(propertyGroup.properties)
      ),
    ].map(({ name, value }) => [name, value])
  );
};

const toComparableColorFormat = (color: string | undefined) => {
  if (!color) return color;
  if (isValidHexColor(color)) {
    return rgbaObjToStr(hexColorToRgbaObj(color));
  }
  return rgbaObjToStr(rgbaStringToObj(color));
};

const getEditablePropertiesOfComponent = (
  scopeData: EnhancedScopeData,
  componentId: ArdoqId
) => {
  const targetComponent = scopeData.componentsById[componentId];
  const tags = scopeData.tags?.filter((tag: APITagAttributes) =>
    tag.components.includes(componentId)
  );
  const fields =
    scopeData.fieldsByModelIdAndTypeId[
      `${targetComponent.model}-${targetComponent.typeId}`
    ];
  return {
    component: {
      ...targetComponent,
      color: toComparableColorFormat(targetComponent.color),
    },
    tags,
    fields,
  };
};

const getEditablePropertiesOfWorkspace = (
  scopeData: EnhancedScopeData,
  workspaceId: ArdoqId
) => {
  return pick(scopeData.workspacesById[workspaceId], [
    'name',
    'description',
    'startView',
    'defaultPerspective',
    'defaultSort',
  ]);
};

const getEditablePropertiesOfReference = (
  scopeData: EnhancedScopeData,
  referenceId: ArdoqId
) => {
  const reference = scopeData.referencesById[referenceId];
  return {
    reference,
    tags: scopeData.tags?.filter((tag: APITagAttributes) =>
      tag.references.includes(referenceId)
    ),
    fields:
      scopeData.fieldsByModelIdAndTypeId[
        `${reference.model}-${reference.type}`
      ],
  };
};

const getEditablePropertiesOfReferenceType = (
  scopeData: EnhancedScopeData,
  referenceTypeId: ArdoqId
) => {
  const referenceType =
    scopeData.typesByModelId[scopeData.models[0]._id].referenceTypesById[
      referenceTypeId
    ];
  return {
    ...referenceType,
    color: toComparableColorFormat(referenceType.color),
  };
};

const getEditablePropertiesOfField = (
  scopeData: EnhancedScopeData,
  fieldTypeId: ArdoqId
) => {
  return scopeData.fieldsById[fieldTypeId];
};

export const getEditableEntityPropertiesToCompare = <T extends APIEntityType>(
  scopeData: EnhancedScopeData,
  entityType: T,
  entityID: ArdoqId
) => {
  switch (entityType) {
    case APIEntityType.COMPONENT:
      return getEditablePropertiesOfComponent(scopeData, entityID);
    case APIEntityType.WORKSPACE:
      return getEditablePropertiesOfWorkspace(scopeData, entityID);
    case APIEntityType.REFERENCE:
      return getEditablePropertiesOfReference(scopeData, entityID);
    case APIEntityType.REFERENCE_TYPE:
      return getEditablePropertiesOfReferenceType(scopeData, entityID);
    case APIEntityType.FIELD:
      return getEditablePropertiesOfField(scopeData, entityID);
    default:
      logWarn(
        Error(
          `Sidebar editor encountered unsupported APIEntityType - ${entityType}`
        ),
        null,
        {
          entityID,
          entityType,
        }
      );
      return undefined;
  }
};

export const getSaveButtonDisabledState = (
  propertyGroups: PropertyGroup[],
  isCreatingNewEntity: boolean,
  currentEditableProperties: ReturnType<
    typeof getEditableEntityPropertiesToCompare<APIEntityType>
  >,
  originalProperties: ReturnType<
    typeof getEditableEntityPropertiesToCompare<APIEntityType>
  >
) => {
  const hasErrors = propertyGroups
    .flatMap(({ properties }) => properties)
    .flatMap(({ errorMessages }) => errorMessages ?? [])
    .some(currentErrorMessage => currentErrorMessage);
  if (isCreatingNewEntity && !hasErrors) return false;
  return hasErrors || isEqual(currentEditableProperties, originalProperties);
};
